import React from "react";
import { Route, Switch, withRouter } from "react-router";
import NotFound from "../pages/not-found/NotFound";

function ScopeMatcherRouter({ match, scopes, defaultScope }) {
  const defautlScopeFilter = scopes.filter((x) => x.name === defaultScope);
  if (defaultScope && defautlScopeFilter.length === 0) {
    throw new Error("Default scope not found");
  }
  return (
    <Switch>
      {scopes.map(({ name, component }) => (
        <Route
          key={name}
          path={`${match.url}/${name}`}
          exact
          component={component}
        />
      ))}
      {defautlScopeFilter.length === 1 ? (
        <Route
          path={`${match.url}/`}
          exact
          component={defautlScopeFilter[0].component}
        />
      ) : null}
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

export default withRouter(ScopeMatcherRouter);
