import {
  AuthorizationError,
  RequiredFieldError,
  TokenizationFailError
} from "./ValidationError";
import Axios from "axios";
import { ADESAO_URL } from '../config';

export async function authorizePayment(
  sessionToken,
  tokenize,
  cardHolder,
  cvv,
  options
) {
  //Implementação só pra testar o simulador

  let cardData;
  try {
    cardData = await tokenize();
  } catch (e) {
    throw new TokenizationFailError((e && e.message) || "Failed to tokenize");
  }
  console.log({cardData})
  try {
    const cardToken = cardData.card.key;
    if (!cvv) {
      throw new RequiredFieldError("cvv");
    }

    if (!cardHolder) {
      throw new RequiredFieldError("cardHolder");
    }

    const payload = buildPayload(sessionToken, cardToken, cardHolder, cvv);
    const response = await sendRequest(options, payload);

    return response.data;
  } catch (err) {
    if (err.response?.data?.statusCode) {
      throw new AuthorizationError(err.response.data.statusCode);
    }
    throw err;
  }
}

function buildPayload(sessionToken, cardToken, cardHolder, cvv) {
  return {
    sessionValidationToken: sessionToken,
    eldoradoToken: cardToken,
    cardHolder: cardHolder,
    cvv: cvv.trim()
  };
}

async function sendRequest(options, payload) {
  return await Axios.request({
    url: ADESAO_URL,
    method: "POST",
    data: payload,
    ...options
  });
}
