import AMEX from "../images/amex.png";
import DINERS from "../images/diners.png";
import ELO from "../images/elo.png";
import HIPERCARD from "../images/hipercard.png";
import MASTERCARD from "../images/mastercard.png";
import VISA from "../images/visa.png";
import DEFAULT from "../images/default.png";

export function selectBrand(brand) {
  switch (brand) {
    case "AMEX":
      return AMEX;
    case "DINERS":
      return DINERS;
    case "ELO":
      return ELO;
    case "HIPERCARD":
      return HIPERCARD;
    case "MASTERCARD":
      return MASTERCARD;
    case "VISA":
      return VISA;
    default:
      return DEFAULT;
  }
}
