import rem from "polished/lib/helpers/rem";
import styled, { createGlobalStyle } from "styled-components";
import styledNormalize from "styled-normalize";
import { em } from "polished";

const _rem = (size) => rem(size, "16px");
const _em = (size) => em(size, "16px");
export const maxWindowSize = 600;
export const colors = {
  darkBlue: "#2A3E52",
  green: "#00ab0f",
  black: "#000",
  white: "#FFF",
  grey: "#E2E5E9",
  bgGrey: "#F8F8F8",
  bgSecondary: "#EFEEEE",
  red: "#D0021B",
  redSoft: "#fb93a0",
  invalidField: "#e70000",
  validField: "#17d615",
};

export const device = {
  mobile: `(max-width: 425px)`,
  tablet: `(min-width: 426px)`,
  desktop: `(min-width: 1025px)`,
};

export const ContentWrapper = styled.div`
  position: relative;
  padding: 0;
`;

export const defaultFontFamily =
  "OpenSans, Helvetica, Arial, Verdana, Tahoma, sans-serif";

export const GlobalStyles = createGlobalStyle`
  ${styledNormalize}

  *{
    box-sizing:border-box;
    margin: 0;
    font-family: ${defaultFontFamily};
  }

  hr{ 
    border: 0; 
    border-top: 1px solid ${colors.grey};
    margin: ${_rem(20)} 0 ;
  }
`;

export { _rem as rem, _em as em };
