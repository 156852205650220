import React from "react";
import { PONTE_ENV } from "../config";

export default React.forwardRef((props, ref) => {
  return (
    <m4u-eldorado-bridge
      hasValidationMessage
      invalidExpirationMessage={"Digite uma data válida"}
      invalidPANMessage={"Confira os números do cartão"}
      env={PONTE_ENV}
      theme={"oi-fibra"}
      ref={ref}
      style={{ height: 205 }}
    />
  );
});
