import React from "react";
import { Div, SpanToExplain } from "../../components/Common";
import { useAppContext } from "../../components/AppContext";
import Container from "../../components/Container";
import { DelayedLoading } from "../../components/Loading";
import useFormState from "../../components/useFormState";
import { TROCA_CARTAO_URL } from "../../config";
import { maxWindowSize } from "../../lib/global-styles";
import { authorizeBroker } from "../../services/authorizeBroker";
import { sendMessage } from "../../services/postMessages";
import { validateCardHolder, validateCvv } from "../../services/validation";
import ChangeCardButton from "./ChangeCardButton";
import CurrentCard from "./CurrentCard";
import CardHolderInput from "../../components/CardHolderInput";
import CVVInput from "../../components/CVVInput";
import PonteEldorado from "../../components/PonteEldorado";

export default function TrocaCartao() {
  const ponteRef = React.useRef();
  const { sessionToken } = useAppContext();
  const [loading, setLoading] = React.useState(false);
  const { formValues, inputProps, touchAll } = useFormState({
    cvv: "",
    cardHolder: "",
  });
  if (!sessionToken) {
    return "Erro";
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    touchAll();
    setLoading(true);
    await authorizeBroker(
      {
        sessionToken,
        tokenize: () => ponteRef.current.tokenize(),
        cardHolder: formValues.cardHolder,
        cvv: formValues.cvv,
      },
      sendMessage,
      { url: TROCA_CARTAO_URL, method: "PUT" }
    );
    setLoading(false);
  };

  return (
    <Container maxWindowSize={maxWindowSize}>
      <form onSubmit={onSubmit}>
        <CurrentCard sessionToken={sessionToken} />
        <PonteEldorado ref={ponteRef} />
        <CardHolderInput
          label={"Nome impresso no cartão"}
          help={"Igual está escrito no cartão"}
          {...inputProps("cardHolder", (value) =>
            validateCardHolder(value) ? null : "Digite um nome"
          )}
        ></CardHolderInput>

        <CVVInput
          label={"Código de segurança"}
          placeholder={"CVV"}
          {...inputProps("cvv", (value) =>
            validateCvv(value) ? null : "Digite um cvv válido"
          )}
          help={"Veja o código na parte de trás do cartão"}
        ></CVVInput>

        {loading && <DelayedLoading />}
        <Div>
          <SpanToExplain>Transação segura</SpanToExplain>
        </Div>
        <ChangeCardButton type={"submit"} disabled={loading}>
          Alterar
        </ChangeCardButton>
      </form>
    </Container>
  );
}
