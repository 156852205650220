import React from "react";
import styled from "styled-components";
import { colors } from "../lib/global-styles";

const Label = styled.label`
  display: block;

  color: #212121;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
`;
const FormDiv = styled.div`
  position: relative;
  margin-bottom: 10px;
  color: #282828;
  font-size: 16px;
  input {
    padding: 14px 8px;
    border: solid 1px #909090;
    color: #282828;
    height: 48px;
  }
  ${({ invalid, touched }) =>
    invalid
      ? `
      input{
        border: 1px solid ${colors.invalidField} !important;
      }
  `
      : touched
      ? `input{
        border: 1px solid ${colors.validField}!important;
      }
      `
      : null}
`;

FormDiv.displayName = "FormDiv";
FormDiv.defaultProps = {
  inputProps: {},
};

const ErrorMessage = styled.div`
  display: block;
  font-size: 14px;
  height: 14px;
  margin-top: 2px;
  color: ${colors.invalidField};
  ${({ visible }) => (visible ? `visibility:visible;` : `visibility:hidden;`)};
`;

const ErrorWrapper = styled.div`
  position: relative;
`;

export default function FormGroup({
  label,
  children,
  errorMessage,
  inputStyle,
  spacing,
  touched,
}) {
  return (
    <FormDiv
      invalid={!!errorMessage}
      touched={touched}
      inputStyle={inputStyle}
      spacing={spacing}
    >
      <Label>{label}</Label>
      <ErrorWrapper>
        {children}
        <ErrorMessage visible={!!errorMessage}>{errorMessage}</ErrorMessage>
      </ErrorWrapper>
    </FormDiv>
  );
}
