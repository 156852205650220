import styled from "styled-components";
import { colors } from "../lib/global-styles";
import { rem } from "polished";

const Button = styled.button`
  width: 100%;
  background-color: ${colors.green};
  border: 0;
  border-radius: 5px;
  padding: ${rem(20)} 0;
  color: ${colors.white};
  font-size: ${rem(20)};
  text-transform: uppercase;
  cursor: pointer;
  ${({ color, background }) => ({ color, background })}
`;

export default Button;
