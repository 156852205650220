import Button from "../../components/Button";
import styled from "styled-components";

const ChangeCardButton = styled(Button)`
  width: 206px;
  height: 54px;
  padding: 0;
  text-transform: none;
`;

export default ChangeCardButton;
