import React, { useEffect } from "react";
import {
  Card,
  Brand,
  Bold,
  DivCard,
  Hr,
  TitleCurrentCard,
  DivCurrentCard,
} from "./Card";
import { getCardByToken } from "./CardService";
import { selectBrand } from "../../services/brand";

export default function CurrentCard({ sessionToken }) {
  const [card, setCard] = React.useState({});

  useEffect(() => {
    const fetchCard = async () => {
      const response = await getCardByToken(sessionToken);
      setCard(response);
    };
    fetchCard();
  }, [sessionToken]);

  return (
    <DivCard>
      <DivCurrentCard>
        <TitleCurrentCard>Cartão cadastrado</TitleCurrentCard>
      </DivCurrentCard>
      <Brand src={selectBrand(card.brand)} />
      <Card>
        Crédito termina com <Bold>{card.last}</Bold>
      </Card>
      <Hr></Hr>
    </DivCard>
  );
}
