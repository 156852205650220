import React from "react";
import { useAppContext } from "../../components/AppContext";
import Container from "../../components/Container";
import DigiFrameSessionMessageBroker from "../../components/DigiFrameSessionMessageBroker";
import { DelayedLoading } from "../../components/Loading";
import { maxWindowSize } from "../../lib/global-styles";
import { getWindowLocation } from "../../services/location.js";

export default function Home({ history }) {
  const { setSessionToken, setScope } = useAppContext();
  const onSuccess = ({ sessionToken, scope }) => {
    setSessionToken(sessionToken);
    setScope(scope);
    history.replace(`/scopematcher/${scope || ""}`);
  };

  React.useEffect(() => {
    if (getWindowLocation().href.endsWith("/simulador")) {
      console.log("redirect to /simulador");
      history.replace("/simulador");
    }
  }, [history]);

  return (
    <Container maxWindowSize={maxWindowSize}>
      <DelayedLoading />
      <DigiFrameSessionMessageBroker onSuccess={onSuccess} />
    </Container>
  );
}
