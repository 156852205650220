import React from "react";
import {
  readyMessage,
  sendMessage,
  sessionSuccessMessage,
} from "../services/postMessages";
import usePostMessageEvent from "./usePostMessageEvent";
import { AUTH_ACTION } from "../config";

export default function DigiFrameSessionMessageBroker({ onSuccess }) {
  usePostMessageEvent("*", (message, answer) => {
    if (message && message.action && message.action === AUTH_ACTION) {
      answer(sessionSuccessMessage());
      console.log("[Oifibra Frame] Received message ", message)
      onSuccess({
        sessionToken: message.sessionValidationToken,
        scope: message.sessionScope,
      });
    }
  });
  React.useEffect(() => {
    sendMessage(readyMessage());
  }, []);
  return <></>;
}
