import { authorizePayment } from "./authorizePayment";
import {
  authorizationSuccessMessage,
  authorizationErrorMessage,
} from "./postMessages";

export function authorizeBroker(
  { sessionToken, tokenize, cardHolder, cvv },
  answer,
  options
) {
  return authorizePayment(
    sessionToken,
    tokenize,
    cardHolder,
    cvv,
    options
  ).then(
    (detail) => {
      answer(authorizationSuccessMessage(detail));
    },
    (error) => {
      answer(authorizationErrorMessage(error));
    }
  );
}
