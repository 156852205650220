import styled from "styled-components";

export const DivCard = styled.div`
  width: 100%;
`;

export const DivCurrentCard = styled.div`
  width: 100%;
  padding-bottom: 2%;
`;

export const TitleCurrentCard = styled.label`
  height: 18px;
  font-family: OpenSans;
  font-size: 18px;
  font-weight: bold;
  color: #282828;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export const Hr = styled.hr`
  height: 1px;
  background-color: #dbdbdb;
`;

export const Card = styled.label`
  height: 20px;
  font-family: OpenSans;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #282828;
`;

export const Brand = styled.img`
  height: 35px;
  width: 54px;
  border-radius: 4px;
  border: solid 1px #979797;
  margin-right: 2%;
  vertical-align: bottom;
`;

export const Bold = styled.span`
  height: 20px;
  font-family: OpenSans;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222222;
  font-weight: bold;
`;
