import React from "react";
import { GlobalStyles } from "./lib/global-styles";
import routes from "./routes";
import { MemoryRouter as Router } from "react-router-dom";
import { AppContext } from "./components/AppContext";
import {
  applyPolyfills,
  defineCustomElements,
} from "@meios-pagamentos/eldorado-bridge-component/loader";

function App() {
  return (
    <>
      <AppContext>
        <GlobalStyles />
        <Router>{routes}</Router>
      </AppContext>
    </>
  );
}

applyPolyfills().then(() => {
  defineCustomElements();
});

export default App;
