import React from "react";
import { Route, Switch } from "react-router-dom";
import ErrorPage from "./pages/error/ErrorPage";
import Home from "./pages/home/Home";
import NotFound from "./pages/not-found/NotFound";
import ScopeMatcher from "./ScopeMatcher";

const routes = (
  <Switch>
    <Route path="/scopematcher" component={ScopeMatcher} />
    <Route path="/error" exact component={ErrorPage} />
    <Route path="/" exact component={Home} />
    <Route path="*" component={NotFound} />
  </Switch>
);
export default routes;
