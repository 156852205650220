export const GET_CARD_URL = sessionId =>
  `${process.env.REACT_APP_OIFIBRA_API_URL}/v1/oifibra/payments/credit-card/session/${sessionId}`;

export const PONTE_ENV = process.env.REACT_APP_PONTE_ELDORADO_ENV;
export const ADESAO_URL = `${process.env.REACT_APP_OIFIBRA_API_URL}/v1/oifibra/payments`;

export const TROCA_CARTAO_URL = `${process.env.REACT_APP_OIFIBRA_WALLET_URL}/v1/oifibra/payments/credit-card`;

export const ERROR_SESSION_TYPE = "session-error";
export const ERROR_AUTHORIZATION_TYPE = "authorization-error";
export const ERROR_VALIDATION_TYPE = "validation-error";
export const ERROR_TOKENIZATION_TYPE = "tokenization-error";
export const UNEXPECTED_ERROR_TYPE = "unexpected-error";

export const SESSION_SUCCESS_ACTION = "session-success";
export const SUCCESS_ACTION = "success";
export const ERROR_ACTION = "error";
export const INFO_ACTION = "info";
export const READY_ACTION = "ready";
export const AUTH_ACTION = "auth";
export const SUBMIT_FORM_ACTION = "submit-form";
