import {
  ERROR_VALIDATION_TYPE,
  ERROR_TOKENIZATION_TYPE,
  ERROR_AUTHORIZATION_TYPE,
} from "../config";

export class AppError extends Error {
  constructor(message, errorType, errorDetail) {
    super(message);
    this.errorType = errorType;
    this.errorDetail = errorDetail;
  }
}

export class RequiredFieldError extends AppError {
  constructor(field) {
    super("Campo obrigatório", ERROR_VALIDATION_TYPE, { field });
  }
}

export class TokenizationFailError extends AppError {
  constructor(detail) {
    super("Erro ao tokenizar campo", ERROR_TOKENIZATION_TYPE, detail);
  }
}

export class AuthorizationError extends AppError {
  constructor(reason) {
    super("Erro ao autorizar compra", ERROR_AUTHORIZATION_TYPE, { reason });
  }
}
