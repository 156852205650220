function prepareMessage(message) {
  return JSON.stringify(message);
}
export function parseMessage(event) {
  try {
    return JSON.parse(event.data);
  } catch (e) {
    console.warn("Não foi possível parsear", event.data);
  }
}
export function isMyMessage(desiredUrl, event) {
  if (
    typeof event.data === "string" &&
    event.data.startsWith("[iFrameSizer]")
  ) {
    return false;
  }
  if (desiredUrl === "*") return true;
  if (!desiredUrl.startsWith(event.origin)) {
    console.warn(
      "Ignorando mensagem por não ser da origem " + desiredUrl,
      event
    );
    return false;
  }
  return true;
}

export function subscribeMessages(url, callback) {
  function receivePostMessage(event) {
    if (!isMyMessage(url, event)) {
      return;
    }
    const answer = (message) =>
      event.source.postMessage(prepareMessage(message), "*");
    const response = parseMessage(event);
    callback(response, answer);
  }
  window.addEventListener("message", receivePostMessage);
  return () => window.removeEventListener("message", receivePostMessage);
}
