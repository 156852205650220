import React from "react";
import styled from "styled-components";
import loadingImage from "../images/loader.gif";
import { colors } from "../lib/global-styles";
const Loading = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${colors.white} url(${loadingImage}) center center no-repeat;
  opacity: 0.8;
  z-index: 1002;
`;

export default Loading;
export function DelayedLoading({ delay = 250 }) {
  const [hasTimedOut, setHasTimedOut] = React.useState(false);
  React.useEffect(() => {
    const timout = setTimeout(() => setHasTimedOut(true), delay);
    return () => clearTimeout(timout);
  });
  return hasTimedOut && <Loading />;
}
