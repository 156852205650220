import React from "react";
import ScopeMatcherRouter from "./components/ScopeMatcherRouter";
import Adesao from "./pages/adesao/Adesao";
import TrocaCartao from "./pages/troca-cartao/TrocaCartao";

export default function ScopeMatcher() {
  return (
    <ScopeMatcherRouter
      scopes={[
        {
          name: "adesao",
          component: Adesao,
        },
        {
          name: "troca-cartao",
          component: TrocaCartao,
        },
      ]}
      defaultScope={"adesao"}
    />
  );
}
