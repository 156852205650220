import React from "react";
import styled from "styled-components";
import { useAppContext } from "../../components/AppContext";
import Container from "../../components/Container";
import { DelayedLoading } from "../../components/Loading";
import useFormState from "../../components/useFormState";
import { maxWindowSize } from "../../lib/global-styles";
import { authorizeBroker } from "../../services/authorizeBroker";
import { sendMessage } from "../../services/postMessages";
import { validateCardHolder, validateCvv } from "../../services/validation";
import PonteEldorado from "../../components/PonteEldorado";
import CardHolderInput from "../../components/CardHolderInput";
import CVVInput from "../../components/CVVInput";
import { ADESAO_URL } from "../../config";

const ButtonSubmit = styled.button`
  height: 50px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #ffd700;
  background-color: #ffd700;
  width: 100%;
  font-size: 18px;
`;
export default function Adesao() {
  const ponteRef = React.useRef();
  const { sessionToken } = useAppContext();
  const [loading, setLoading] = React.useState(false);
  const { formValues, inputProps, touchAll } = useFormState({
    cvv: "",
    cardHolder: "",
  });

  if (!sessionToken) {
    return "Erro";
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    touchAll();
    setLoading(true);
    await authorizeBroker(
      {
        sessionToken,
        tokenize: () => ponteRef.current.tokenize(),
        cardHolder: formValues.cardHolder,
        cvv: formValues.cvv,
      },
      sendMessage,
      { url: ADESAO_URL }
    );
    setLoading(false);
  };
  return (
    <Container maxWindowSize={maxWindowSize}>
      <form onSubmit={onSubmit}>
        <PonteEldorado ref={ponteRef} />
        <CardHolderInput
          label={"Nome impresso no cartão"}
          {...inputProps("cardHolder", (value) =>
            validateCardHolder(value) ? null : "Digite um nome"
          )}
        ></CardHolderInput>

        <CVVInput
          label={"Código de segurança"}
          placeholder={"CVV"}
          {...inputProps("cvv", (value) =>
            validateCvv(value) ? null : "Digite um cvv válido"
          )}
        ></CVVInput>

        {loading && <DelayedLoading />}

        <ButtonSubmit type={"submit"} disabled={loading}>
          FINALIZAR PEDIDO
        </ButtonSubmit>
      </form>
    </Container>
  );
}
