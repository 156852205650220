import styled from "styled-components";

export const DivAlterCard = styled.div`
  width: 100%;
  padding-bottom: 4%;
`;

export const Div = styled.div`
  padding-top: 1%;
  padding-bottom: 1%;
`;

export const SpanToExplain = styled.span`
  height: 19px;
  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #909090;
`;
