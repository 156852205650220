import FormGroup from "./FormGroup";
import InputCvv from "./InputCvv";
import React from "react";
import Help from "./Help";

export default function CVVInput({
  label,
  touched,
  errorMessage,
  placeholder,
  help,
  ...otherProps
}) {
  return (
    <>
      <FormGroup
        label={"Código de segurança"}
        touched={touched}
        errorMessage={touched && errorMessage}
      >
        <InputCvv
          name="cvv"
          data-testid="cvv"
          placeholder={placeholder || ""}
          {...otherProps}
        />

        {help ? <Help>{help}</Help> : null}
      </FormGroup>
    </>
  );
}
