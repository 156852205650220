import React from "react";
import { subscribeMessages } from "./messageBroker";

export default function usePostMessageEvent(url, callback) {
  React.useEffect(() => {
    const unsubscribe = subscribeMessages(url, callback);
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);
}
