import React from "react";

const appContext = React.createContext();
export const useAppContext = () => React.useContext(appContext);

export function AppContext({ children }) {
  const [sessionToken, setSessionToken] = React.useState(null);
  const [scope, setScope] = React.useState(null);

  return (
    <appContext.Provider
      value={{
        sessionToken,
        setSessionToken,
        scope,
        setScope,
      }}
    >
      {children}
    </appContext.Provider>
  );
}
