import {
  ERROR_ACTION,
  ERROR_SESSION_TYPE,
  INFO_ACTION,
  READY_ACTION,
  SESSION_SUCCESS_ACTION,
  SUCCESS_ACTION,
  UNEXPECTED_ERROR_TYPE,
} from "../config";
import { AppError, AuthorizationError } from "./ValidationError";

function prepareMessage(message) {
  return JSON.stringify(message);
}
export function sendMessage(message) {
  console.log("[postMessages] Sending", message);
  window.parent.postMessage(prepareMessage(message), "*");
}
export function readyMessage() {
  return { action: READY_ACTION };
}
export function sessionSuccessMessage() {
  return { action: SESSION_SUCCESS_ACTION };
}
export function sessionErrorMessage(error) {
  return {
    action: ERROR_ACTION,
    error: { name: ERROR_SESSION_TYPE, detail: error.message },
  };
}
export function authorizationErrorMessage(error) {
  if (!(error instanceof AppError)) {
    return {
      action: ERROR_ACTION,
      error: { name: UNEXPECTED_ERROR_TYPE, detail: error },
    };
  }
  const errorName = error.errorType;
  const errorDetail = error.errorDetail;
  if (error instanceof AuthorizationError) {
    return {
      action: ERROR_ACTION,
      error: { name: errorName, detail: errorDetail },
    };
  } else {
    return {
      action: INFO_ACTION,
      info: { name: errorName, detail: errorDetail },
    };
  }
}
export function authorizationSuccessMessage(detail) {
  return {
    action: SUCCESS_ACTION,
    detail,
  };
}
