import React from "react";
import { InputMask } from "./Input";
const InputCvv = React.forwardRef((props, ref) => (
  <InputMask
    mask={"9999"}
    type={"tel"}
    ref={ref}
    formatChars={{ "9": "[0-9]", "?": "[0-9]" }}
    maskChar=" "
    {...props}
    style={{ width: 60 }}
  />
));

export default InputCvv;
