import { Div, SpanToExplain } from "./Common";
import React from "react";

export default function Help({ children }) {
  return (
    <Div>
      <SpanToExplain>{children}</SpanToExplain>
    </Div>
  );
}
