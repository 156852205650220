import FormGroup from "./FormGroup";
import Input from "./Input";
import React from "react";
import Help from "./Help";

export default function CardHolderInput({
  label,
  touched,
  errorMessage,
  placeholder,
  help,
  ...otherProps
}) {
  return (
    <FormGroup
      label={label}
      touched={touched}
      errorMessage={touched && errorMessage}
    >
      <Input
        name="cardHolder"
        data-testid="cardHolder"
        placeholder={placeholder || ""}
        {...otherProps}
      />

      {help ? <Help>{help}</Help> : null}
    </FormGroup>
  );
}
